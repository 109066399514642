import React from "react";
import MyRoutes from "./routes/MyRoutes";
import './App.css'
import './assets/i18n'


function App() {
  return (
      <div className="App">
          <MyRoutes />
      </div>
  );
}

export default App;